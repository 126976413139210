import React from "react";
import Aside from "./Aside";
import Header from "./Header";
import { Outlet } from "react-router-dom";

const index = (props) => {
  return (
    <main className="dashboard_layout_section d-flex">
      <div>
        <Aside />
      </div>
      <div className="w-100 dashboard_layout_main px-4">
        <Header userAuth={props.userAuth} userData={props.userData} />
        {/* <div className="d-flex justify-content-end mt-3 pe-3">
        </div> */}
        <main className="mt-5 dashboard_layout_child_section mb-5">
          <Outlet />
        </main>
      </div>
    </main>
  );
};

export default index;
