import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/style/global.style.css";
import "./assets/style/style.css";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./context/UserContext";
import MainRoute from "./routes/MainRoute";
import { UIProvider } from "./context/UiContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <UIProvider>
        <MainRoute />
      </UIProvider>
    </UserProvider>
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
