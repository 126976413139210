import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout/index";

// lazy loading
const DashboardPage = React.lazy(() => import("../pages/app/Dashboard"));
const PolicyPage = React.lazy(() => import("../pages/app/PolicyPage"));
const ApplicationPage = React.lazy(() =>
  import("../pages/app/ApplicationPage")
);
const EmployeePage = React.lazy(() => import("../pages/app/EmployeePage"));
const CalenderPage = React.lazy(() => import("../pages/app/CalenderPage"));
const SettingPage = React.lazy(() => import("../pages/app/SettingPage"));
const ProfilePage = React.lazy(() => import("../pages/app/ProfilePage"));

const AppRoute = () => {
  return (
    <>
      <Routes>
        <Route element={<DashboardLayout />}>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/policy" element={<PolicyPage />} />
          <Route path="/application" element={<ApplicationPage />} />
          <Route path="/employee" element={<EmployeePage />} />
          <Route path="/calendar" element={<CalenderPage />} />
          <Route path="/setting" element={<SettingPage />} />
          <Route path="/profile" element={<ProfilePage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoute;
