import React, { useState } from "react";
import { useUserContext } from "../../context/UserContext";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import UserAvatar from "../../assets/images/common/user.png";
import { useUIContext } from "../../context/UiContext";
import CompleteProcessModal from "../Modals/CompleteProcessModal";

const Header = () => {
  const { user, logout } = useUserContext();
  const { headerPageDetail } = useUIContext();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [allDeviceCheck, setAllDeviceCheck] = useState(false);
  const navigate = useNavigate();
  const logoutHandler = async () => {
    // await logout();
    // navigate("/", { replace: true });
    // navigate("/app/logout", { replace: true });
    setShowLogoutModal(true);
  };

  const submitLogoutHandler = async () => {
    console.log(allDeviceCheck);
    await logout(allDeviceCheck);
    setAllDeviceCheck(false);
    navigate("/", { replace: true });
    setShowLogoutModal(false);
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };
  return (
    <>
      <header className="top_header_section d-flex justify-content-between align-items-center my-3 py-2 px-4 rounded-2 shadow-sm bg-white">
        <div className="top_header_text">
          <h1>{headerPageDetail?.title} </h1>
          {/* <span>Primary</span> */}
        </div>
        <div className="d-flex align-items-center gap-xl-4">
          {/* <label className="top_header_search d-block  position-relative">
          <input type="text" placeholder="Search..." />
          <button>
            <Search className="fs-ss-16" />
          </button>
        </label> */}
          <div className="top_header_dropDown">
            <Dropdown>
              <Dropdown.Toggle variant="">
                <div className="top_header_user_info d-flex align-items-center gap-xl-2">
                  <p>{user && user.name}</p>
                  <img
                    className="cursor-pointer"
                    // src="https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg"
                    src={UserAvatar}
                    alt=""
                  />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/app/profile">Profile</Dropdown.Item>
                <Dropdown.Item href="#" onClick={logoutHandler}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
      <CompleteProcessModal
        size="sm"
        title={`Logout`}
        successMessageText="Logout"
        BodyMessage={() => {
          return (
            <div>
              <p>Are you sure you want to Logout?</p>

              <div className="form-check mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={(e) => {
                    // console.log(e.target.checked);
                    setAllDeviceCheck(e.target.checked);
                  }}
                />
                <label
                  className="form-check-label font-16 font-weight-600"
                  for="flexCheckDefault"
                >
                  Logout from all device.
                </label>
              </div>
            </div>
          );
        }}
        showModal={showLogoutModal}
        handleModal={handleCloseLogoutModal}
        className="logout-header-modal"
        // loading={loadingProcessPassword}
        actionSuccess={async () => {
          submitLogoutHandler();
        }}
      />
    </>
  );
};

export default Header;
