import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import PrivateRoute from "./PrivateRoute";
import Loading from "../components/Loading";
import React, { Suspense } from "react";
import { useUserContext } from "../context/UserContext";
import AppRoute from "./AppRoute";

// lazy loading
const LoginPage = React.lazy(() => import("../pages/Login"));
const ForgetPasswordPage = React.lazy(() => import("../pages/ForgetPassword"));
const SetPasswordPage = React.lazy(() => import("../pages/SetPassword"));

function MainRoute() {
  const { isLoading } = useUserContext();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Toaster position="top-right" reverseOrder={false} />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgetPasswordPage />} />
            <Route
              path="/set-password/:id/:token/:code"
              element={<SetPasswordPage />}
            />

            <Route
              path="/app/*"
              element={
                <PrivateRoute>
                  <AppRoute />
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default MainRoute;
