import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <div id="splash-screen" className="splash-screen">
      <span>
        <Spinner animation="border" variant="dark" />
      </span>
    </div>
  );
};

export default Loading;
