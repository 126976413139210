import React, { useEffect } from "react";
import Logo from "../../assets/images/logos/logo.png";
import { Link, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { ChevronLeft } from "react-bootstrap-icons";
import { useUIContext } from "../../context/UiContext";
import { asideMenu } from "../../data/aside-menu";

const Aside = () => {
  const {
    showMenu,
    setShowMenu,
    activeMenu,
    setActiveMenu,
    isMobileSize,
    setMobileSize,
    setHeaderPageDetail,
  } = useUIContext();
  const location = useLocation();
  const currentUrl = location.pathname;
  const handleShowMenu = () => {
    if (isMobileSize) {
      setShowMenu(false);
    } else {
      setShowMenu(!showMenu);
    }
  };
  useEffect(() => {
    const currentMenuItem = asideMenu.filter(
      (item) => item.link === currentUrl
    )[0];
    if (currentMenuItem) {
      setActiveMenu(currentMenuItem?.id);
      setHeaderPageDetail({
        title: currentMenuItem.title,
        detail: currentMenuItem.detail,
      });
    }
  }, []);

  const handleWindowResize = () => {
    // Update the menu state based on the window width
    if (window.innerWidth <= 925) {
      setMobileSize(true);
      setShowMenu(false);
    } else {
      setMobileSize(false);
      setShowMenu(true);
    }
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Initial check for window width on component mount
    handleWindowResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <aside
      className={`aside_section  ${
        showMenu
          ? "aside_section_showMenu_width"
          : "aside_section_showHide_width"
      }`}
    >
      {/* icon  */}
      <button
        className={`btn aside_section_menu_option  ${
          showMenu
            ? "aside_section_menu_option_showOption"
            : "aside_section_menu_option_hideOption"
        }`}
        onClick={handleShowMenu}
      >
        <ChevronLeft className="fs-ss-16 " />
      </button>
      <div className="flex-grow-1">
        <div
          className={`aside_logo_section  ${
            showMenu
              ? "aside_section_section_show"
              : "aside_section_section_hide"
          }`}
        >
          <img src={Logo} alt="Logo" />
          {/* <h3 className="text-primary-color ">LEAVE MGMT</h3> */}
        </div>
        <section className="aside_menu_section ">
          {asideMenu?.map((menu) => {
            return (
              <div
                className={`aside_menu_item ${
                  activeMenu === menu.id && "aside_menu_item_active"
                }`}
                style={{ marginTop: "12px" }}
                onClick={() => {
                  setActiveMenu(menu.id);

                  setHeaderPageDetail({
                    title: menu.title,
                    detail: menu.detail,
                  });
                }}
              >
                <Link
                  to={menu.link}
                  className="aside_menu_item_link text-decoration-none "
                >
                  <div className="d-flex align-items-center py-1">
                    {/* <img src={menu.icon} alt="user-icon" /> */}
                    <span className="menu-icon">
                      {" "}
                      <span className={`svg-icon`}>
                        <SVG src={menu.icon} />
                      </span>
                    </span>
                    <p
                      className={`${
                        showMenu
                          ? "aside_section_section_show"
                          : "aside_section_section_hide"
                      }`}
                    >
                      {menu.name}
                    </p>
                  </div>
                </Link>
              </div>
            );
          })}
        </section>
      </div>
      {/* <div
        className={`aside_menu_logout text-center  bg-zk-primary  overflow-hidden ${
          showMenu ? "p-3" : "py-3"
        }`}
        onClick={logoutHandler}
      >
        {showMenu ? <span>Logout</span> : <Power fontSize={20} />}
      </div> */}
    </aside>
  );
};

export default Aside;
