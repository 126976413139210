import Image1 from "../assets/images/common/dasboard.svg";
import Image2 from "../assets/images/common/policy.svg";
import Image3 from "../assets/images/common/applications.svg";
import Image4 from "../assets/images/common/users.svg";
import Image5 from "../assets/images/common/calenderIcon.svg";
// import Image6 from "../assets/images/common/setting.svg";
import Image6 from "../assets/images/common/general-setting.svg";

export const asideMenu = [
  {
    id: 1,
    name: "Dashboard",
    link: "/app",
    icon: Image1,
    scope: ["ADMIN"],
    title: "Dashboard",
    detail: "Overview",
  },
  {
    id: 2,
    name: "Calendar",
    link: "/app/calendar",
    icon: Image5,
    scope: ["ADMIN"],
    title: "Calendar",
    detail: "Overview",
  },

  {
    id: 3,
    name: "Applications",
    link: "/app/application",
    icon: Image3,
    scope: ["ADMIN"],
    title: "Applications",
    detail: "Overview",
  },
  {
    id: 4,
    name: "Employees",
    link: "/app/employee",
    icon: Image4,
    scope: ["ADMIN"],
    title: "Employees",
    detail: "Overview",
  },
  {
    id: 5,
    name: "Policy",
    link: "/app/policy",
    icon: Image2,
    scope: ["ADMIN"],
    title: "Policy",
    detail: "Overview",
  },
  {
    id: 6,
    name: "Setting",
    link: "/app/setting",
    icon: Image6,
    scope: ["ADMIN"],
    title: "Setting",
    detail: "Overview",
  },
];
